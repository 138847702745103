import React from "react";
import { useSelector } from "react-redux";
import RatePopup from "../components/modals/RatePopup";
import PageLayout from "../components/PageLayout";

const Orders = () => {
  const orders = useSelector((state) => state.orders.value);

  const displayStars = (rate) => {
    let stars = [];
    for (let i = 1; i <= rate; i++) {
      stars.push(<i key={i} className="fas fa-star star"></i>);
    }
    for (let i = rate + 1; i <= 5; i++) {
      stars.push(<i key={i} className="far fa-star"></i>);
    }
    return stars;
  };

  return (
    <PageLayout title="Orders">
      <div className="orders">
        <h2 className="page-title">Your Orders</h2>
        <ul className="list-container">
          {orders.map((el) => (
            <li className="list-item">
              <h4>
                <span className={"badge badge-" + el.status.toLowerCase()}>{el.status}</span> {el.title}
              </h4>
              <span>By {el.by}</span>
              <span>{el.condition}</span>
              <span>
                {el.status.toLowerCase() === "delivered" &&
                  (el.rate ? displayStars(parseInt(el.rate, 10)) : <RatePopup id={el.id} title={el.title} />)}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </PageLayout>
  );
};

export default Orders;
