import React from "react";
import Badge from "../../components/Badge";
import PageLayout from "../../components/PageLayout";

const BadgesPage = () => {
  return (
    <PageLayout title="Badges Components">
      <div className="components">
        <header>
          <h2>Badges</h2>
        </header>
        <div className="content">
          <div className="components-section">
            <h1>
              This Primary Badge <Badge primary>Badge</Badge>
            </h1>
            <h2>
              This Success Badge <Badge success>Badge</Badge>
            </h2>
            <h3>
              This Info Badge <Badge info>Badge</Badge>
            </h3>
            <h4>
              This Danger Badge <Badge danger>Badge</Badge>
            </h4>
            <h5>
              This Warning Badge <Badge warning>Badge</Badge>
            </h5>
            <h6>
              This Secondary Badge <Badge secondary>Badge</Badge>
            </h6>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default BadgesPage;
