import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { remove } from "../redux/notifications";
import MenuDropdown from "./MenuDropdown";

const Notifications = () => {
  const notifications = useSelector((state) => state.notifications.value);
  const dispatch = useDispatch();

  return (
    <MenuDropdown
      className="notification"
      trigger={
        <li>
          <i className="fas fa-bell"></i>
          <span>{notifications.length}</span>
        </li>
      }
    >
      <div className="dropdown">
        {notifications.length === 0 ? (
          <p className="alter">There is no notification</p>
        ) : (
          <ul>
            {notifications.map((el, index) => (
              <React.Fragment key={index}>
                <li>
                  {el} <span className="fas fa-times close" onClick={() => dispatch(remove(el))}></span>
                </li>
              </React.Fragment>
            ))}
          </ul>
        )}
      </div>
    </MenuDropdown>
  );
};

export default Notifications;
