import React from "react";
import { Button, ButtonGroup } from "../../components/Button";
import PageLayout from "../../components/PageLayout";

const ButtonsPage = () => {
  return (
    <PageLayout title="Buttons Components">
      <div className="components">
        <header>
          <h2>Buttons</h2>
        </header>
        <div className="content">
          <div className="components-section">
            <h4>Pick your Color</h4>
            <Button primary>Primary</Button>
            <Button success>Success</Button>
            <Button info>Info</Button>
            <Button warning>Warning</Button>
            <Button danger>Danger</Button>
            <br />
            <Button primary light>
              Primary
            </Button>
            <Button success light>
              Success
            </Button>
            <Button info light>
              Info
            </Button>
            <Button warning light>
              Warning
            </Button>
            <Button danger light>
              Danger
            </Button>
          </div>

          <div className="components-section">
            <h4>Buttons with Label</h4>
            <Button primary>
              <i className="fas fa-chevron-left"></i> Left
            </Button>
            <Button primary>
              Right <i className="fas fa-chevron-right"></i>
            </Button>
            <Button success>
              <i className="fas fa-check"></i> Success
            </Button>
            <Button info>
              <i className="fas fa-exclamation"></i> Info
            </Button>
            <Button warning>
              <i className="fas fa-exclamation-triangle"></i> Warning
            </Button>
            <Button danger>
              <i className="far fa-trash-alt"></i> Danger
            </Button>
          </div>

          <div className="components-section">
            <h4>Pick your Size</h4>
            <Button primary xsmall>
              XSmall
            </Button>
            <Button primary small>
              Small
            </Button>
            <Button primary>Regular</Button>
            <Button primary large>
              Large
            </Button>
          </div>

          <div className="components-section">
            <h4>Pick your Style</h4>
            <Button primary>Default</Button>
            <Button primary round>
              Rounded
            </Button>
            <Button primary round>
              <i className="fas fa-heart"></i> With Icon
            </Button>
            <Button primary fround>
              <i className="fas fa-heart"></i>
            </Button>
          </div>

          <div className="components-section">
            <h4>Button Group</h4>
            <ButtonGroup>
              <Button info>Left</Button>
              <Button info>Middle</Button>
              <Button info>Right</Button>
            </ButtonGroup>{" "}
            <ButtonGroup>
              <Button info>1</Button>
              <Button info>2</Button>
              <Button info>3</Button>
              <Button info>4</Button>
              <Button info>5</Button>
              <Button info>6</Button>
            </ButtonGroup>
          </div>

          <div className="components-section" style={{ flexBasis: "100%" }}>
            <h4>Social buttons</h4>
            <Button facebook>Facebook</Button>
            <Button twitter>Twitter</Button>
            <Button youtube>Youtube</Button>
            <Button linkedin>LinkedIn</Button>
            <Button instagram>Instagram</Button>
            <Button pinterest>Pinterest</Button>
            <Button behance>Behance</Button>
            <Button dribbble>Dribbble</Button>
            <Button reddit>Reddit</Button>

            <br />

            <Button facebook light>
              Facebook
            </Button>
            <Button twitter light>
              Twitter
            </Button>
            <Button youtube light>
              Youtube
            </Button>
            <Button linkedin light>
              LinkedIn
            </Button>
            <Button instagram light>
              Instagram
            </Button>
            <Button pinterest light>
              Pinterest
            </Button>
            <Button behance light>
              Behance
            </Button>
            <Button dribbble light>
              Dribbble
            </Button>
            <Button reddit light>
              Reddit
            </Button>

            <br />

            <Button facebook fround />
            <Button twitter fround />
            <Button youtube fround />
            <Button linkedin fround />
            <Button instagram fround />
            <Button pinterest fround />
            <Button behance fround />
            <Button dribbble fround />
            <Button reddit fround />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ButtonsPage;
