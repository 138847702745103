import React from "react";
import PageLayout from "../components/PageLayout";

export default function Login() {
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <PageLayout title="Login" noNavigation>
      <div className="login">
        <div className="bannar">
          <h1>Manage Your App</h1>
        </div>
        <div className="login-form">
          <h2>Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-input">
              <label htmlFor="email">Email:</label>
              <input type="email" name="email" id="email" placeholder="Email" onChange={handleChange} />
            </div>
            <div className="form-input">
              <label htmlFor="password">Password:</label>
              <input type="password" name="password" id="password" placeholder="Password" onChange={handleChange} />
            </div>
            <button className="btn btn-success">Login</button>
          </form>
        </div>
      </div>
    </PageLayout>
  );
}
