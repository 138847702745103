import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

/*
 * MneuDropdown Component to make dropdown menus
 * @props
 * triger: JSX (The trigger element)
 * className: string (class name of the dropdown content)
 */

export default function MenuDropdown(props) {
  const [open, setOpen] = useState(false);
  const dropdownMenu = useRef(null);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [dropdownMenu]);

  return (
    <div className={"dropdown " + props.className}>
      {React.cloneElement(props.trigger, { onClick: handleOpen })}
      {open && (
        <motion.div
          ref={dropdownMenu}
          className={"dropdown-content"}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {props.children}
        </motion.div>
      )}
    </div>
  );
}
