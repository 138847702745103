import React, { useState } from "react";
import Modal from "../Modal";

export default function UploadPhoto() {
  const [product, setProduct] = useState({
    images: [],
    error: "",
  });

  const ImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setProduct({ ...product, images: e.target.files });
    }
  };

  const handleSubmit = (e) => {
    let { images, error } = product;
    if (images.length === 0) error = "This field should not be empty!";
    else if (images[0].type !== "image/jpeg" && images[0].type !== "image/png")
      error = "This Image should be JPG or PNG extension!";
    else error = "";

    if (error === "") {
      setProduct({
        images: [],
        error: "",
      });
      return true;
    }
    setProduct({ ...product, error });
    return false;
  };

  return (
    <Modal
      modelClass="upload-photo"
      title="Upload New Photo"
      submit={handleSubmit}
      submitBtnText="Upload"
      trigger={
        <button className="btn btn-primary only-btn-icon-mobile">
          <i className="fas fa-plus"></i>
          <span>Upload New</span>
        </button>
      }
    >
      <form>
        <div className="form-input">
          <input type="file" name="image" id="image" onChange={ImageChange} />
          <small className="text-danger">{product.error}</small>
        </div>
      </form>
    </Modal>
  );
}
