import React from "react";

/*
 * Button Component to add a button to the page
 * @props
 * [primary, secondary, success, warning, danger, info, link]
 * [light]
 * [round, fround]
 * [large, small, xsmall]
 * [facebook, twitter, linkedin, pinterest, instagram, reddit, youtube, behance, dribble]
 * [disabled]
 * onClick
 *
 */
export function Button(props) {
  const handleBtnType = () => {
    if (props.primary && props.light) {
      return "btn btn-primary-light";
    } else if (props.primary) {
      return "btn btn-primary";
    }
    if (props.secondary && props.light) {
      return "btn btn-secondary-light";
    } else if (props.secondary) {
      return "btn btn-secondary";
    }
    if (props.success && props.light) {
      return "btn btn-success-light";
    } else if (props.success) {
      return "btn btn-success";
    }
    if (props.warning && props.light) {
      return "btn btn-warning-light";
    } else if (props.warning) {
      return "btn btn-warning";
    }
    if (props.danger && props.light) {
      return "btn btn-danger-light";
    } else if (props.danger) {
      return "btn btn-danger";
    }
    if (props.info && props.light) {
      return "btn btn-info-light";
    } else if (props.info) {
      return "btn btn-info";
    }
    if (props.link) {
      return "btn btn-link";
    }
    return "btn";
  };
  const handleBtnSize = () => {
    if (props.size === "large" || props.large) {
      return "btn-lg";
    }
    if (props.size === "small" || props.small) {
      return "btn-sm";
    }
    if (props.size === "xsmall" || props.xsmall) {
      return "btn-xm";
    }
    return "";
  };
  const handleBtnRound = () => {
    if (props.round) {
      return "btn-round";
    }
    if (props.fround) {
      return "btn-round-full";
    }
    return "";
  };
  const handleBtnSocial = () => {
    if (props.facebook && props.light) {
      return "btn-facebook-light";
    } else if (props.facebook) {
      return "btn-facebook";
    }
    if (props.twitter && props.light) {
      return "btn-twitter-light";
    } else if (props.twitter) {
      return "btn-twitter";
    }
    if (props.linkedin && props.light) {
      return "btn-linkedin-light";
    } else if (props.linkedin) {
      return "btn-linkedin";
    }
    if (props.pinterest && props.light) {
      return "btn-pinterest-light";
    } else if (props.pinterest) {
      return "btn-pinterest";
    }
    if (props.reddit && props.light) {
      return "btn-reddit-light";
    } else if (props.reddit) {
      return "btn-reddit";
    }
    if (props.youtube && props.light) {
      return "btn-youtube-light";
    } else if (props.youtube) {
      return "btn-youtube";
    }
    if (props.behance && props.light) {
      return "btn-behance-light";
    } else if (props.behance) {
      return "btn-behance";
    }
    if (props.instagram && props.light) {
      return "btn-instagram-light";
    } else if (props.instagram) {
      return "btn-instagram";
    }
    if (props.dribbble && props.light) {
      return "btn-dribbble-light";
    } else if (props.dribbble) {
      return "btn-dribbble";
    }

    return "";
  };
  const handleSocialIcon = () => {
    if (props.facebook) {
      return "fab fa-facebook-square";
    }
    if (props.twitter) {
      return "fab fa-twitter";
    }
    if (props.linkedin) {
      return "fab fa-linkedin";
    }
    if (props.pinterest) {
      return "fab fa-pinterest";
    }
    if (props.reddit) {
      return "fab fa-reddit-alien";
    }
    if (props.instagram) {
      return "fab fa-instagram";
    }
    if (props.youtube) {
      return "fab fa-youtube";
    }
    if (props.behance) {
      return "fab fa-behance";
    }
    if (props.dribbble) {
      return "fab fa-dribbble";
    }

    return "";
  };

  return (
    <button
      className={`${handleBtnType()} ${handleBtnSize()} ${handleBtnRound()} ${handleBtnSocial()} ${
        props.className || ""
      }`}
      onClick={props.onClick}
    >
      {handleSocialIcon() && <i className={handleSocialIcon()}></i>}
      {props.children}
    </button>
  );
}

export function ButtonGroup(props) {
  return <div className="btn-group">{props.children}</div>;
}
