import React, { useEffect, useState } from "react";
import PageLayout from "../components/PageLayout";
import InfiniteScroll from "react-infinite-scroll-component";
import UploadPhoto from "../components/modals/UploadPhoto";

const API_KEY = "OiQG1kZ8eAZzGb3XcCmC8QQMxHBpj687DJ96UsC02do";
export default function Gallery() {
  const [images, setImages] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const fetchImages = async () => {
    const response = await fetch(`https://api.unsplash.com/photos/?client_id=${API_KEY}&page=${page}&per_page=20`);
    const data = await response.json();
    if (data.length === 0) return setHasMore(false);
    return setImages(images.concat(data));
  };

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    setPage(page + 1);
  }, [images]);
  return (
    <PageLayout title="Gallery">
      <div className="gallery">
        <header>
          <h2 className="page-title">Gallery</h2>
          <UploadPhoto />
        </header>
        <main>
          <InfiniteScroll
            dataLength={images.length}
            next={fetchImages}
            hasMore={hasMore}
            loader={
              <div className="loading">
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div className="gallery-container">
              {images.map((el) => (
                <div key={el.id} className="gallery-item">
                  <img src={el.urls.regular} alt={el.id} loading="lazy" />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </main>
      </div>
    </PageLayout>
  );
}
