import React from "react";

/*
 * Badge Component to add a badge to the page
 * @props
 * [primary, secondary, success, warning, danger, info, link]
 *
 */
export default function Badge(props) {
  const handleBadgeType = () => {
    if (props.type === "primary" || props.primary) {
      return "badge badge-primary";
    }
    if (props.type === "secondary" || props.secondary) {
      return "badge badge-secondary";
    }
    if (props.type === "success" || props.success) {
      return "badge badge-success";
    }
    if (props.type === "warning" || props.warning) {
      return "badge badge-warning";
    }
    if (props.type === "danger" || props.danger) {
      return "badge badge-danger";
    }
    if (props.type === "info" || props.info) {
      return "badge badge-info";
    }
    return "badge badge-default";
  };
  return <span className={handleBadgeType()}>{props.children}</span>;
}
