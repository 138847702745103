import React from "react";
import { Link } from "react-router-dom";
import MenuDropdown from "./MenuDropdown";

export default function MoreOptions() {
  return (
    <MenuDropdown trigger={<i className="fas fa-chevron-down icon"></i>} className="more-option">
      <ul>
        <li>
          <Link to="/settings/user">Settings</Link>
        </li>
        <li>
          <Link to="/login">Logout</Link>
        </li>
      </ul>
    </MenuDropdown>
  );
}
